<template>
  <div class="personDevice">
    <div class="contentView">
      <div class="title">
        设备类型:
      </div>
      <div class="content">
        {{ type }}
      </div>
      <div style="clear: both"></div>
    </div>
    <div class="contentView">
      <div class="title">
        设备编码:
      </div>
      <div class="content">
        {{ label }}
      </div>
      <div style="clear: both"></div>
    </div>
  </div>

<!--  <div style="width: 20px;height: 20px">-->
<!--    <svg-icon name="active-asset-H2"></svg-icon>-->
<!--  </div>-->

</template>

<script>
export default {
  name: 'personDevice',
  props: {
    device: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {

    type() {
      if (this.$valueIsExist(this.device, 'type') == false) {
        return '-'
      }
      return this.device['type']
    },
    label() {
      if (this.$valueIsExist(this.device, 'label') == false) {
        return '-'
      }
      return this.device['label']
    }
  }
}
</script>

<style lang="scss" scoped>

.personDevice {
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  max-width: 200px;

  .contentView {
    margin: 3px 0px 3px 0px;

    .title {
      width: 60px;
      height: 20px;
      font-size: 13px;
      text-align: right;
      float: left;
      line-height: 20px;
    }

    .content {
      margin-right: 5px;
      margin-left: 5px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  }
}

</style>
