let bedPersonListMixin = {
  data: function() {
    return {
      page: 1,
      pageSize: 50,
      totalCount: 0,
      inPut: '',
      loading: false,
      tableData: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadTableData(true, true)
    })
  },
  methods: {
    currentChange: function(index) {
      this.page = index
      this.loadTableData(false, true)
    },
    reset: function() {
      this.page = 1
      this.pageSize = 30
    },
    loadTableData: function(reset, loading) {
      if (reset) {
        this.reset()
      }
      if (loading) {
        this.loading = true
      }

      let loadData = () => {
        this.$api.asset2Module.getPersonList({
          query: this.inPut,
          page: this.page,
          pageSize: this.pageSize
        }).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.tableData = res.data
            this.totalCount = res.totalCount
          }
          this.loading = false

        }).catch(e => {
          this.loading = false
        })
      }

      loadData()
    }
  }
}

export default bedPersonListMixin
