<template>
  <div class="personTable">
    <div class="personTableHeader">
      监测人员({{ totalCount }}人)
    </div>
    <el-table
        :show-header="false"
        border
        stripe
        v-loading="loading"
        :height="height"
        :data="tableData"
        class="checkAssetList" @row-click="rowClick"
    >
      <el-table-column>
        <template slot-scope="scope">
          <person-view :person-info="scope.row"></person-view>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import PersonView from '@/views/bedPersonList/personView'
export default {
  name: 'personTable',
  components: { PersonView },
  props: {
    totalCount: {
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'calc(100% - 190px)'
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods:{
    rowClick:function(item) {

    },
  },

}
</script>


<style lang="scss" scoped>

.personTable {
  .personTableHeader {
    width: 100%;
    height: 40px;
    background-color: white;
    line-height: 40px;
    text-align: center;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
  }
}

.color_medium {
  color: #5fb0ed !important;
}

.color_warning {
  color: #e68b35 !important;
}

.color_danger {
  color: #e44545 !important;
}

.color_default {
  color: #666 !important;
}

.color_principal {
  color: #333 !important;
}

</style>
