<template>
  <div class="personView">
    <div class="contentView">
      <div class="title">
        姓名:
      </div>
      <div class="content">
        {{ name }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        护理等级:
      </div>
      <div class="content">
        {{ nursingLevel }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        护理人员:
      </div>
      <div class="content">
        {{ nurseName }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        科室:
      </div>
      <div class="content">
        {{ tenantName }}
      </div>
      <div style="clear: both"></div>
    </div>



    <div class="contentView">
      <div class="title">
        当前位置:
      </div>
      <div class="content">
        {{ place }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        心率:
      </div>
      <div class="content">
        {{ heart }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        呼吸:
      </div>
      <div class="content">
        {{ breath }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentView">
      <div class="title">
        佩戴设备:
      </div>
      <div style="float: left;margin-left: 10px">
<!--        <person-device v-for="(item, index) in sensors" :device="item"></person-device>-->
        <svg-icon width="20px" height="20px"  name="active-asset-H2" icon-class="active-asset-H2" className="icon"></svg-icon>
      </div>

      <div style="clear: both"></div>
    </div>

  </div>
</template>

<script>
import PersonDevice from '@/views/bedPersonList/personDevice'
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'personView',
  components: { SvgIcon, PersonDevice },
  props: {
    personInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    breath() {
      if (this.$valueIsExist(this.personInfo, 'breath') == false) {
        return '-'
      }
      return this.personInfo['breath']
    },
    heart() {
      if (this.$valueIsExist(this.personInfo, 'heart') == false) {
        return '-'
      }
      return this.personInfo['heart']
    },
    place() {
      if (this.$valueIsExist(this.personInfo, 'place') == false) {
        return '-'
      }
      return this.personInfo['place']
    },
    sensors() {
      if (this.$valueIsExist(this.personInfo, 'sensors') == false) {
        return []
      }
      return this.personInfo['sensors']
    },
    name() {
      if (this.$valueIsExist(this.personInfo, 'name') == false) {
        return '-'
      }
      return this.personInfo['name']
    },
    nursingLevel() {
      if (this.$valueIsExist(this.personInfo, 'nursingLevel') == false) {
        return '-'
      }
      return this.personInfo['nursingLevel']
    },
    nurseName() {
      if (this.$valueIsExist(this.personInfo, 'nurseName') == false) {
        return '-'
      }
      return this.personInfo['nurseName']
    },
    tenantName() {
      if (this.$valueIsExist(this.personInfo, 'tenantName') == false) {
        return '-'
      }
      return this.personInfo['tenantName']
    }
  },
  methods: {
    formatLabelTypeIcon(label) {
      let list = [{
        name: '资产',
        fullName: '资产设备',
        typeId: 'ASSET',
        icon: 'zichan'
      },
        {
          name: 'Z2',
          fullName: 'Z2普通标签',
          typeId: 'Z2',
          icon: 'Z2'
        },
        {
          name: 'Z3',
          fullName: 'Z3高资标签',
          typeId: 'Z3',
          icon: 'Z3'
        },
        {
          name: 'Z3-2',
          fullName: 'Z3-2高资标签',
          typeId: 'Z32',
          icon: 'Z3'
        },
        {
          name: 'Z4',
          fullName: 'Z4高资标签',
          typeId: 'Z4',
          icon: 'Z4'
        },
        {
          name: 'Z5',
          fullName: 'Z5高资标签',
          typeId: 'Z5',
          icon: 'Z5'
        },
        {
          name: '基站',
          fullName: '基站设备',
          typeId: 'W1',
          icon: 'jizhan'
        },
        {
          name: '信标',
          fullName: '信标设备',
          typeId: 'X1',
          icon: 'X1'
        },
        {
          name: 'H2手环',
          fullName: 'H2手环',
          typeId: 'H2',
          icon: 'H2'
        },
        {
          name: 'S2手环',
          fullName: 'S2手环',
          typeId: 'S21',
          icon: 'H2'
        },
        {
          name: 'S2Pro手环',
          fullName: 'S2Pro手环',
          typeId: 'S2',
          icon: 'S2Pro'
        },
        {
          name: '拉绳',
          fullName: '拉绳设备',
          typeId: 'L1',
          icon: 'L1'
        },
        {
          name: '脚环',
          fullName: '脚环设备',
          typeId: 'J1',
          icon: 'J1'
        },
        {
          name: 'B1脚环',
          fullName: 'B1脚环',
          typeId: 'B1',
          icon: 'J1'
        },
        {
          name: '床带',
          fullName: '床带设备',
          typeId: 'C1',
          icon: 'C1'
        },
        {
          name: '胸卡',
          fullName: '胸卡设备',
          typeId: 'K1',
          icon: 'K1'
        },
        {
          name: 'C3胸卡',
          fullName: 'C3胸卡设备',
          typeId: 'C3',
          icon: 'K1'
        }]
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.typeId === label.type) {
          return item.icon
        }
      }
      return 'qita'
    }
  }
}
</script>

<style lang="scss" scoped>

.personView {
  .contentView {
    width: 100%;
    margin: 3px 0px 3px 0px;

    .title {
      width: 80px;
      height: 20px;
      font-size: 13px;
      text-align: right;
      float: left;
      line-height: 20px;
    }

    .content {
      width: calc(100% - 90px);
      float: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }

  }
}

</style>
