<template>
  <div class="bedPersonList">
    <h-stack class="searchFillter" align-items="flex-start">
      <div class="searchFillterBGView">
        <el-input
            placeholder="请输入搜索人姓名"
            v-model="inPut"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>

      <v-stack justify-content="center" style="margin-right: 5px;">
        <button style="outline: none;margin-top: 5px;margin-bottom: 5px;background-color: #85ce61;height: 40px;"
                class="fillterTitle"
                @click="startSearch"
        >搜索
        </button>
      </v-stack>
    </h-stack>

    <person-table
        class="personList"
        height="calc(100% - 40px)"
        :loading="loading"
        :table-data="tableData"
        :total-count="totalCount">

    </person-table>

    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>

  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import VStack from '@/components/vStack/vStack'
import bedPersonListMixin from '@/views/bedPersonList/mixin/bedPersonListMixin'
import PersonTable from '@/views/bedPersonList/personTable'

export default {
  name: 'bedPersonList',
  mixins:[bedPersonListMixin],
  components: { PersonTable, VStack, HStack },
  data() {
    return {

    }
  },
  methods:{
    startSearch:function() {
      this.loadTableData(true, true);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.bedPersonList {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #eeeeee;

  .searchFillter {
    width: 100%;
    background-color: white;

    .searchFillterBGView {
      width: calc(100% - 60px);
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      margin-right: 10px;

      .flllterChoose {
        float: left;
        width: 100%;
        margin-top: 5px;


        .checkAssetChooseSelect {
          float: right;
          width: calc(100% - 95px);
          margin-left: 5px;
          overflow: scroll;
          max-height: 30px;


          .checkAssetChooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            margin-left: 5px;
            font-size: 13px;
            color: $color_primary;
            line-height: 28px;
            min-height: 28px;
            border-radius: 5px;
            margin-bottom: 2px;
          }
        }

      }

    }

    .fillterTitle {
      text-align: center;
      width: 70px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      background-color: $color_primary;
      border: none;
    }
  }

  .personList {
    width: 100%;
    height: calc(100% - 90px);
  }

}


</style>
